import VueRouter from 'vue-router';
import { frontendURL } from '../helper/URLHelper';
import dashboard from './dashboard/dashboard.routes';
import store from '../store';
import { validateLoggedInRoutes } from '../helper/routeHelpers';
import AnalyticsHelper from '../helper/AnalyticsHelper';
import { buildPermissionsFromRouter } from '../helper/permissionsHelper';
import DeobfuscateAccountId from '../api/deobfuscateAccountId';

const routes = [...dashboard.routes];

export const router = new VueRouter({ mode: 'history', routes });
export const routesWithPermissions = buildPermissionsFromRouter(routes);

const obfuscateRoute = async accountId => {
  const response = await DeobfuscateAccountId.getObfuscatedAccountId(accountId);
  return response;
};

export const validateAuthenticateRoutePermission = async (
  to,
  next,
  { getters }
) => {
  const { isLoggedIn, getCurrentUser: currentUser } = getters;

  if (!isLoggedIn) {
    window.location = '/app/login';
    return '/app/login';
  }
  // Resolved conflicts with the upstream branch
  // Accepted Current Changes and changed the code to the following
  //   * Removed the roleWiseRoutes from the validateLoggedInRoutes function
  const nextRoute = validateLoggedInRoutes(to, currentUser);

  if (nextRoute === 'app/login') {
    window.open(
      'https://myclickfunnels.com/messagehub-not-authorized',
      '_self'
    );
    // eslint-disable-next-line consistent-return
    return;
  }

  if (nextRoute) {
    // eslint-disable-next-line consistent-return
    return next(frontendURL(nextRoute));
  }

  /* eslint-disable no-restricted-globals */
  if (!isNaN(to.params.accountId)) {
    const [currentAccount = {}] = currentUser.accounts.filter(
      account => account.id === Number(to.params.accountId)
    );

    if (currentAccount) {
      to.params.accountId = currentAccount.obfuscated_id;
      // eslint-disable-next-line consistent-return
      return next({ name: to.name, params: to.params });
    }

    await obfuscateRoute(to.params.accountId).then(obfuscatedRoute => {
      to.params.accountId = obfuscatedRoute;
      // eslint-disable-next-line consistent-return
      return next({ name: to.name, params: to.params });
    });
  } else {
    // eslint-disable-next-line consistent-return
    return next();
  }
  /* eslint-enable no-restricted-globals */

  return null;
};

export const initalizeRouter = () => {
  const userAuthentication = store.dispatch('setUser');

  router.beforeEach(async (to, from, next) => {
    AnalyticsHelper.page(to.name || '', {
      path: to.path,
      name: to.name,
    });

    // if (validateSSOLoginParams(to)) {
    //   console.log("validateSSOLoginParams");
    //   clearBrowserSessionCookies();
    //   next();
    //   return;
    // }
    //
    // userAuthentication.then(() => {
    //   if (!to.name) {
    //     const { isLoggedIn, getCurrentUser: user } = store.getters;
    //     if (isLoggedIn) {
    //       console.log("isLoggedIn"+user.account_id)
    //       return next(frontendURL(`accounts/${user.account_id}/dashboard`));
    //     }
    //     return next('/app/login');
    //   }
    //
    //   return validateRouteAccess(to, from, next, store);
    userAuthentication.then(() => {
      return validateAuthenticateRoutePermission(to, next, store);
    });
  });
};

export default router;
