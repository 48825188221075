<template>
  <span class="info-label">
    <fluent-icon :size="size" :icon="icon" :type="type" :view-box="viewBox" />
    {{ infoText }}
  </span>
</template>
<script>
import FluentIcon from '../FluentIcon/DashboardIcon.vue';

export default {
  name: 'InfoLabel',
  components: {
    FluentIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: [String, Number],
      default: '20',
    },
    type: {
      type: String,
      default: 'outline',
    },
    viewBox: {
      type: String,
      default: '0 0 24 24',
    },
    infoText: {
      type: String,
      default: '',
    },
  },
};
</script>
