<template>
  <div class="flex flex-col w-full items-start">
    <h2
      class="text-xl font-medium mb-1 text-slate-800 dark:text-slate-100 break-words"
    >
      {{ headerTitle }}
    </h2>
    <p
      v-dompurify-html="headerContent"
      class="text-sm w-full text-slate-600 dark:text-slate-300"
    />
    <p v-if="channelDocs[channelName]">
      <a :href="channelDocs[channelName]" target="_blank"
        ><info-label
          icon="info"
          size="18"
          class=""
          info-text="Learn how to setup your Inbox "
      /></a>
    </p>
  </div>
</template>

<script>
import wootConstants from 'dashboard/constants/globals';
import InfoLabel from 'shared/components/ui/InfoLabel.vue';

export default {
  components: {
    InfoLabel,
  },
  props: {
    headerTitle: { type: String, default: '' },
    headerContent: { type: String, default: '' },
    headerDocumentation: { type: String, default: '' },
  },
  data() {
    return {
      channelName: this.$route.params.sub_page,
      channelDocs: wootConstants.CHANNEL_DOCS,
    };
  },
};
</script>
