/* global axios */
import ApiClient from './ApiClient';

class DeobfuscateAccountId extends ApiClient {
  constructor() {
    super('', {});
  }

  async getAccountId(obfuscated_id) {
    let res = await axios.get(
      `${this.apiVersion}/accounts/${obfuscated_id}/deobfuscate_id`
    );

    return res.data.account_id;
  }

  async getObfuscatedAccountId(account_id) {
    let res = await axios.get(
      `${this.apiVersion}/accounts/${account_id}/obfuscate_id`
    );

    return res.data.obfuscated_id;
  }
}

export default new DeobfuscateAccountId();
